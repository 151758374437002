<template>
	<div
		id="ecom"
		class="background-white w-100 mb-60 py-60 px-80 shadow-lg rounded"
	>
		<!-- Loader -->
		<div
			v-if="loading"
			href="#"
			class="relative flex justify-center"
		>
			<div class="flex justify-center">
				<i class="fas fa-spinner fa-spin fa-lg color-blue"></i>
			</div>
		</div>

		<div v-if="!loading">
			<h1 class="mb-20">Nethandilsavtala</h1>

			<!-- Website  -->

			<div class="grid grid-2 grid-gap-x-30 align-end">
				<InputGroup
					v-model="form.salesPlaceWebsite"
					label="Endalig heimasíðu addressa"
					placeholder="https://www.dømi.fo"
					:required="true"
				/>

				<Transition name="slide-fade">
					<InputGroup
						v-if="form.websiteIsNotLive"
						v-model="form.temporaryWebsite"
						placeholder="https://staging.dømi.fo"
						label="Fyribilsleinkja til heimasíðu"
					/>
				</Transition>
			</div>

			<div>
				<label class="inline-block mb-30 cursor-pointer">
					<input
						type="checkbox"
						v-model="form.websiteIsNotLive"
					/>

					Heimasíðan er ikki alment tøk enn
				</label>
			</div>

			<!-- Website Login -->

			<div>
				<label class="inline-block mb-30 cursor-pointer">
					<input
						type="checkbox"
						v-model="form.websiteIsLocked"
					/>

					Heimasíðan hevur lás
				</label>
			</div>

			<Transition name="slide-fade">
				<div
					v-if="form.websiteIsLocked"
					class="grid grid-2 grid-gap-x-30 align-end"
				>
					<InputGroup
						v-model="form.websiteUsername"
						label="Brúkaranavn"
					/>

					<InputGroup
						v-model="form.websitePassword"
						label="Loyniorð"
					/>
				</div>
			</Transition>

			<hr class="mb-30" />

			<div class="grid grid-2 grid-gap-x-30 align-end">
				<InputGroup
					v-model="form.salesPlace"
					label="Sølustað"
					:required="true"
				/>

				<InputGroup
					v-model="form.description"
					label="Stutt lýsing av vørum ella tænastum"
					:required="true"
				/>

				<InputGroup
					v-model="form.expectedCard"
					label="Væntað kortsøla um árið (VISA/ MasterCard)"
					:required="true"
				/>

				<InputGroup
					v-model="form.expected"
					label="Væntað miðalkeyp pr sølu"
					:required="true"
				/>
			</div>

			<hr class="mb-30" />

			<h2 class="mb-30">Hvussu væntast sølubýtið at verða í %?</h2>

			<div class="grid grid-2 grid-gap-x-20">
				<InputGroup
					v-model="form.salesNorth"
					label="Føroyar (og hini norðurlond)"
				/>
				<InputGroup
					v-model="form.salesDenmark"
					label="Danmark %"
				/>
				<InputGroup
					v-model="form.salesEU"
					label="EU %"
				/>
				<InputGroup
					v-model="form.salesUS"
					label="USA %"
				/>
				<InputGroup
					v-model="form.salesOther"
					label="Aðrir heimspartar %"
				/>
			</div>

			<hr class="mb-30" />

			<div class="grid grid-2 grid-gap-x-20 align-end">
				<InputGroup
					v-model="form.delivery"
					label="Hvussu er útberingin? (talgilt, post, e.l.)"
					:required="true"
				/>
				<InputGroup
					v-model="form.deliveryTime"
					label="Dagar frá handilin er gjørdur til vøran er hjá kunda"
					:required="true"
				/>
			</div>

			<!-- Sælger I varer eller ydelser via en hjemmeside som ikke er jeres? -->
			<YesNo
				v-model="form.website"
				label="Selja tit vørur ella tænastur gjøgnum heimasíðu sum ikki er tykkara?"
			/>
			<InputGroup
				v-model="form.stockKeeper"
				label="Hvør eigur vørugoymsluna?"
				:required="true"
			/>

			<YesNo
				v-model="form.websitePaymentLink"
				label="Bjóða tit gjaldsleinkju gjøgnum heimasíðuna?"
			/>
			<YesNo
				v-model="form.paymentLink"
				label="Bjóða tit gjaldsleinkju í handilshølunum um kundin er fysiskt til staðar?"
			/>

			<!-- Kan jeres kunder gemme sine kortoplysninger til senere brug? (forenklet kortbetaling) -->
			<YesNo
				v-model="form.saveCard"
				label="Kunnu tykkara kundar goyma sínar kortupplýsingar til seinni keyp (einkla kortgjalding)"
			/>
			<transition name="slide-fade">
				<template v-if="form.saveCard">
					<YesNo
						v-model="form.saveCardApp"
						label="Um ja: í eini app?"
					/>
				</template>
			</transition>

			<!-- Recurring payments -->
			<YesNo
				v-model="form.recurring"
				label="Bjóða tit haldaragjald? (recurring payments) "
			/>

			<!-- Mobile Pay Online -->
			<YesNo
				v-model="form.mobilePay"
				label="Bjóða tit Mobile Pay Online?"
			/>

			<!-- Handelsbetingelser på hjemmesiden -->
			<div>
				<YesNo
					v-model="form.handelsbetingelser"
					label="Eru handilstreytirnar á heimasíðuni?"
				/>
			</div>

			<Transition name="slide-fade">
				<InputGroup
					v-if="form.handelsbetingelser != undefined && form.handelsbetingelser"
					v-model="form.termsAndConditionsLink"
					label="Leinkja til handilstreytir"
					placeholder="https://www.dømi.fo/handilstreytir"
				/>
			</Transition>

			<Transition name="slide-fade">
				<a
					v-if="form.handelsbetingelser != undefined && !form.handelsbetingelser"
					@click.prevent="openFileInput"
					class="btn inline-block mb-20"
				>
					Legg fílu upp
				</a>
			</Transition>

			<div
				v-if="form.handelsbetingelser != undefined"
				class="color-gray mb-30"
				style="font-style: italic"
			>
				<span class="color-red">*</span> Handilstreytirnar skulu vera á donskum ella á enskum
			</div>

			<!-- Bestillingsvarer -->
			<YesNo
				v-model="form.bestillingsvarer"
				label="Bjóða tit bíleggingarvørur?"
			/>

			<transition name="slide-fade">
				<template v-if="form.bestillingsvarer">
					<div class="grid grid-2 grid-gap-x-20">
						<InputGroup
							v-model="form.bestillingsvarerAmount"
							label="Hvussu stórur % partur av kortsøluni er bíleggingarvørur? "
						/>
						<InputGroup
							v-model="form.bestillingsvarerDelivery"
							label="Hvussu long er avgreiðslutíðin á bíleggingarvørum?"
						/>
					</div>
				</template>
			</transition>

			<!-- Deposit -->
			<YesNo
				v-model="form.deposit"
				label="Skal rindast depositum við bílegging?"
			/>
			<transition name="slide-fade">
				<template v-if="form.deposit">
					<div class="grid grid-2 grid-gap-x-20">
						<InputGroup
							v-model="form.depositAmount"
							label="Um ja, hvussu stórur % partur?"
						/>
						<InputGroup
							v-model="form.depositWhen"
							label="Nær rindar kundin restina?"
						/>
						<InputGroup
							v-model="form.depositHow"
							label="Hvussu rindar kundin restina? "
						/>
					</div>
				</template>
			</transition>
		</div>

		<hr class="mb-30" />

		<h2 class="mb-20">Skjøl</h2>

		<p class="mb-20 color-grey">Til Swedbank avtalu er tørvur á hesum skjølum:</p>

		<ul v-if="['P/f', 'Sp/f'].includes(companyType)">
			<li class="mb-5">
				<strong class="block">Tekningarútskrift</strong>
				<em
					class="color-grey"
					style="font-size: 13px"
				>
					Finst á www.skraseting.fo
				</em>
			</li>

			<li class="mb-5">
				<strong class="block">Seinasta roknskapin ella sjálvuppgávu</strong>
			</li>
		</ul>

		<ul v-else-if="['fa.', 'ÍVF'].includes(companyType)">
			<li class="mb-5">
				<strong class="block">Tekningarútskrift</strong>
				<em
					class="color-grey"
					style="font-size: 13px"
				>
					Finst á www.skraseting.fo
				</em>
			</li>

			<li class="mb-5">
				<strong class="block">Seinasta roknskapin ella sjálvuppgávu</strong>
			</li>

			<li class="mb-5">
				<strong class="block">Skrásetingarprógv úr Vinnuskránni</strong>
				<em
					class="color-grey"
					style="font-size: 13px"
				>
					Finst á Vinnuglugganum hjá Taks
				</em>
			</li>
		</ul>

		<ul v-else>
			<li class="mb-5">
				<strong class="block">Ognaryvirlit</strong>
			</li>

			<li class="mb-5">
				<strong class="block">Tekningarútskrift</strong>
				<em
					class="color-grey"
					style="font-size: 13px"
				>
					Finst á www.skraseting.fo
				</em>
			</li>

			<li class="mb-5">
				<strong class="block">Seinasta roknskapin ella sjálvuppgávu</strong>
			</li>
		</ul>

		<p
			class="color-grey"
			style="font-size: 12px"
		>
			<strong>GG:</strong> Tú leggur skjal upp við at trýsta á "Legg skjal upp" niðanfyri.
		</p>
	</div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import InputGroup from '@/components/InputGroup.vue';
import YesNo from '@/components/YesNo.vue';

export default {
	name: 'ECom',

	props: ['value', 'ownerSignatures', 'companyType'],

	components: {
		InputGroup,
		YesNo,
	},

	data() {
		return {
			signatures: 1,
			loading: true,
			form: {
				salesPlace: '',
				websiteIsNotLive: '',
				salesPlaceWebsite: '',
				temporaryWebsite: '',
				websiteIsLocked: '',
				websiteUsername: '',
				websitePassword: '',
				hasTermsAndConditions: '',
				termsAndConditionsLink: '',
				description: '',
				expectedCard: '',
				expected: '',
				salesDenmark: '',
				salesNorth: '',
				salesEU: '',
				salesUS: '',
				salesOther: '',
				delivery: '',
				deliveryTime: '',
				website: undefined,
				stockKeeper: '',
				websitePaymentLink: undefined,
				paymentLink: undefined,
				saveCard: undefined,
				saveCardApp: undefined,
				recurring: undefined,
				mobilePay: undefined,
				handelsbetingelser: undefined,
				bestillingsvarer: undefined,
				bestillingsvarerAmount: '',
				bestillingsvarerDelivery: '',
				deposit: undefined,
				depositAmount: '',
				depositWhen: '',
				depositHow: '',
				isListedCompany: '',
			},
		};
	},

	validations: {
		form: {
			salesPlace: {
				required,
			},

			salesPlaceWebsite: {
				required,
			},

			description: {
				required,
			},

			expectedCard: {
				required,
			},

			expected: {
				required,
			},

			delivery: {
				required,
			},

			deliveryTime: {
				required,
			},

			website: {
				required,
			},

			stockKeeper: {
				required,
			},

			websitePaymentLink: {
				required,
			},

			paymentLink: {
				required,
			},

			saveCard: {
				required,
			},

			recurring: {
				required,
			},

			mobilePay: {
				required,
			},

			handelsbetingelser: {
				required,
			},

			bestillingsvarer: {
				required,
			},

			deposit: {
				required,
			},
		},
	},

	mounted() {
		this.load(this.value);

		this.loading = false;

		window.addEventListener('file-uploaded', () => {
			window.scroll({ top: document.body.offsetHeight, behavior: 'smooth' });
		});
	},

	methods: {
		openFileInput() {
			const element = document.querySelector('.file-input-button');

			element?.click?.();
		},

		load(data) {
			if (!data) {
				return;
			}

			this.form.salesPlace = data['ecom_Salgsstedets markedsnavn'];
			this.form.salesPlaceWebsite = data['ecom_Salgsstedets hjemmeside wwwadresse'];
			this.form.description = data['ecom_Beskrivelse af vare eller service ydelse feks salg af tøj til børn og unge'];
			this.form.expectedCard = data['ecom_Forventet omsætning VISAMC pr år kr'];
			this.form.expected = data['ecom_Forventet gennemsnitskøb kr'];

			this.form.websiteIsNotLive = data['ecom_websiteIsNotLive'];
			this.form.temporaryWebsite = data['ecom_temporaryWebsite'];
			this.form.websiteIsLocked = data['ecom_websiteIsLocked'];
			this.form.websiteUsername = data['ecom_websiteUsername'];
			this.form.websitePassword = data['ecom_websitePassword'];
			this.form.hasTermsAndConditions = data['ecom_hasTermsAndConditions'];
			this.form.termsAndConditionsLink = data['ecom_termsAndConditionsLink'];

			if (data['ecom_DK %']) {
				this.form.salesDenmark = data['ecom_DK %'];
			}

			if (data['ecom_Norden %']) {
				this.form.salesNorth = data['ecom_Norden %'];
			}

			if (data['ecom_EU %']) {
				this.form.salesEU = data['ecom_EU %'];
			}

			if (data['ecom_US %']) {
				this.form.salesUS = data['ecom_US %'];
			}

			if (data['ecom_DK %']) {
				this.form.salesOther = data['ecom_Øvr %'];
			}

			this.form.delivery = data['ecom_Leveringsform for varer og tjenesteydelser digitalt post bud etc'];
			this.form.deliveryTime = data['ecom_Antal dage fra betaling til levering'];

			if (data['ecom_Ja']) {
				this.form.website = true;
			}
			if (data['ecom_Nej']) {
				this.form.website = false;
			}

			this.form.stockKeeper = data['ecom_Juridisk virksomhedsnavn på ejeren af varelageret'];

			if (data['ecom_Ja link web']) {
				this.form.websitePaymentLink = true;
			}
			if (data['ecom_Nej link web']) {
				this.form.websitePaymentLink = false;
			}

			if (data['ecom_Ja link fys']) {
				this.form.paymentLink = true;
			}
			if (data['ecom_Nej link fys']) {
				this.form.paymentLink = false;
			}

			if (data['ecom_Ja2']) {
				this.form.saveCard = true;
			}
			if (data['ecom_Nej2']) {
				this.form.saveCard = false;
			}

			if (data['ecom_Ja3']) {
				this.form.saveCardApp = true;
			}
			if (data['ecom_Nej3']) {
				this.form.saveCardApp = false;
			}

			if (data['ecom_Ja4']) {
				this.form.recurring = true;
			}
			if (data['ecom_Nej4']) {
				this.form.recurring = false;
			}

			if (data['ecom_Ja5']) {
				this.form.mobilePay = true;
			}
			if (data['ecom_Nej5']) {
				this.form.mobilePay = false;
			}

			if (data['ecom_Ja6']) {
				this.form.handelsbetingelser = true;
			}
			if (data['ecom_Nej6']) {
				this.form.handelsbetingelser = false;
			}

			if (data['ecom_Ja Bestillingsvarer']) {
				this.form.bestillingsvarer = true;
			}
			if (data['ecom_Nej Bestillingsvarer']) {
				this.form.bestillingsvarer = false;
			}

			this.form.bestillingsvarerAmount = data['ecom_Andel bestillingsvarer'];
			this.form.bestillingsvarerDelivery = data['ecom_Leveringstid'];

			if (data['ecom_Ja Depositum']) {
				this.form.deposit = true;
			}
			if (data['ecom_Nej Depositum']) {
				this.form.deposit = false;
			}

			this.form.depositAmount = data['ecom_Andel depositum'];
			this.form.depositWhen = data['ecom_Tid for restbetaling'];
			this.form.depositHow = data['ecom_Hvordan betales rest?'];
		},
	},

	watch: {
		'$v.form.$invalid': function () {
			this.$emit('valid', !this.$v.$invalid);
		},

		form: {
			deep: true,

			handler(value) {
				const data = {};

				data['ecom_Salgsstedets markedsnavn'] = value.salesPlace;
				data['ecom_Salgsstedets hjemmeside wwwadresse'] = value.salesPlaceWebsite;
				data['ecom_websiteIsNotLive'] = value.websiteIsNotLive;
				data['ecom_temporaryWebsite'] = value.temporaryWebsite;
				data['ecom_websiteIsLocked'] = value.websiteIsLocked;
				data['ecom_websiteUsername'] = value.websiteUsername;
				data['ecom_websitePassword'] = value.websitePassword;
				data['ecom_hasTermsAndConditions'] = value.hasTermsAndConditions;
				data['ecom_termsAndConditionsLink'] = value.termsAndConditionsLink;

				data['ecom_Beskrivelse af vare eller service ydelse feks salg af tøj til børn og unge'] = value.description;
				data['ecom_Forventet omsætning VISAMC pr år kr'] = value.expectedCard;
				data['ecom_Forventet gennemsnitskøb kr'] = value.expected;

				if (value.salesDenmark) {
					data['ecom_Danmark'] = true;
					data['ecom_DK %'] = value.salesDenmark;
				}

				if (value.salesNorth) {
					data['ecom_Øvrige Norden'] = true;
					data['ecom_Norden %'] = value.salesNorth;
				}

				if (value.salesEU) {
					data['ecom_Øvrige EU'] = true;
					data['ecom_EU %'] = value.salesEU;
				}

				if (value.salesUS) {
					data['ecom_USA'] = true;
					data['ecom_US %'] = value.salesUS;
				}

				if (value.salesOther) {
					data['ecom_Øvrige verdensdele'] = true;
					data['ecom_Øvr %'] = value.salesOther;
				}

				data['ecom_Leveringsform for varer og tjenesteydelser digitalt post bud etc'] = value.delivery;
				data['ecom_Antal dage fra betaling til levering'] = value.deliveryTime;

				// <YesNo v-model="form.website" label="Sælger I varer eller ydelser via en hjemmeside som ikke er jeres?" />
				if (value.website != undefined) {
					if (value.website) {
						data['ecom_Ja'] = true;
						data['ecom_Nej'] = null;
					}
					if (!value.website) {
						data['ecom_Ja'] = null;
						data['ecom_Nej'] = true;
					}
				}

				data['ecom_Juridisk virksomhedsnavn på ejeren af varelageret'] = value.stockKeeper;

				// <YesNo v-model="form.websitePaymentLink" label="Tilbyder I betalingslink via hjemmesiden?" />
				if (value.websitePaymentLink != undefined) {
					if (value.websitePaymentLink) {
						data['ecom_Ja link web'] = true;
						data['ecom_Nej link web'] = null;
					}

					if (!value.websitePaymentLink) {
						data['ecom_Ja link web'] = null;
						data['ecom_Nej link web'] = true;
					}
				}

				// <YesNo v-model="form.paymentLink" label="Tilbyder I betalingslink i fysisk miljø (når Kortkunderne befinder sig på et fysisk Salgssted)?" />
				if (value.paymentLink != undefined) {
					if (value.paymentLink) {
						data['ecom_Ja link fys'] = true;
						data['ecom_Nej link fys'] = null;
					}
					if (!value.paymentLink) {
						data['ecom_Ja link fys'] = null;
						data['ecom_Nej link fys'] = true;
					}
				}

				// <YesNo v-model="form.saveCard" label="Kan jeres kunder gemme sine kortoplysninger til senere brug? (forenklet kortbetaling)" />
				if (value.saveCard != undefined) {
					if (value.saveCard) {
						data['ecom_Ja2'] = true;
						data['ecom_Nej2'] = null;
					}
					if (!value.saveCard) {
						data['ecom_Ja2'] = null;
						data['ecom_Nej2'] = true;
					}
				}

				// <YesNo v-model="form.saveCardApp" label="I en App?" />
				if (value.saveCardApp != undefined) {
					if (value.saveCardApp) {
						data['ecom_Ja3'] = true;
						data['ecom_Nej3'] = null;
					}
					if (!value.saveCardApp) {
						data['ecom_Ja3'] = null;
						data['ecom_Nej3'] = true;
					}
				}

				// <YesNo v-model="form.recurring" label="Skal I tilbyde abonnementsbetaling? (recurring payments)" />
				if (value.recurring != undefined) {
					if (value.recurring) {
						data['ecom_Ja4'] = true;
						data['ecom_Nej4'] = null;
					}
					if (!value.recurring) {
						data['ecom_Ja4'] = null;
						data['ecom_Nej4'] = true;
					}
				}

				// <YesNo v-model="form.mobilePay" label="Skal I tilbyde Mobile Pay Online?" />
				if (value.mobilePay != undefined) {
					if (value.mobilePay) {
						data['ecom_Ja5'] = true;
						data['ecom_Nej5'] = null;
					}
					if (!value.mobilePay) {
						data['ecom_Ja5'] = null;
						data['ecom_Nej5'] = true;
					}
				}

				// <YesNo v-model="form.handelsbetingelser" label="Findes der handelsbetingelser på hjemmesiden? (Hvis nej, vedhæft dem sammen med aftalen)" />
				if (value.handelsbetingelser != undefined) {
					if (value.handelsbetingelser) {
						data['ecom_Ja6'] = true;
						data['ecom_Nej6'] = false;
					}

					if (!value.handelsbetingelser) {
						data['ecom_Ja6'] = null;
						data['ecom_Nej6'] = true;
					}
				}

				// <YesNo v-model="form.bestillingsvarer" label="Skal I tilbyde bestillingsvarer?" />
				if (value.bestillingsvarer != undefined) {
					if (value.bestillingsvarer) {
						data['ecom_Ja Bestillingsvarer'] = true;
						data['ecom_Nej Bestillingsvarer'] = null;
					}
					if (!value.bestillingsvarer) {
						data['ecom_Ja Bestillingsvarer'] = true;
						data['ecom_Nej Bestillingsvarer'] = true;
					}
				}

				// <InputGroup v-model="form.bestillingsvarerAmount" label="Hvis Ja, hvor stor andel i % af omsætningen med kort er bestillingsvarer?" />
				data['ecom_Andel bestillingsvarer'] = value.bestillingsvarerAmount;

				// <InputGroup v-model="form.bestillingsvarerDelivery" label="Hvor lang leveringstid gælder ved bestillingsvarer?" />
				data['ecom_Leveringstid'] = value.bestillingsvarerDelivery;

				// <YesNo v-model="form.deposit" label="Betales depositum i forbindelse med bestilling?" />
				if (value.deposit != undefined) {
					if (value.deposit) {
						data['ecom_Ja Depositum'] = true;
						data['ecom_Nej Depositum'] = null;
					}
					if (!value.deposit) {
						data['ecom_Ja Depositum'] = null;
						data['ecom_Nej Depositum'] = true;
					}
				}

				// <InputGroup v-model="form.depositAmount" label="Hvis Ja, hvor stor andel i %?" />
				data['ecom_Andel depositum'] = value.depositAmount;
				// <InputGroup v-model="form.depositWhen" label="Hvornår finder restbetaling sted?" />
				data['ecom_Tid for restbetaling'] = value.depositWhen;
				// <InputGroup v-model="form.depositHow" label="Hvordan finder restbetaling sted?" />
				data['ecom_Hvordan betales rest?'] = value.depositHow;

				this.$emit('input', { ...data });
			},
		},
	},
};
</script>
